import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react'
import { RootState } from '../store';
import { List, Row, Space, Typography } from 'antd'
import { QRCodeSVG } from 'qrcode.react'
import { useIntl } from 'react-intl';
import ErrorPage from '../ErrorPage'

interface CertActionVariables {
  IDENTITY_ID: string;
  IDENTITY_GROUP_ID: string;
  CERT_TEMPLATE_ID: string;
  CERT_ID: string;
  NETWORKS: string;
}
const generatePassword = () => {
  let charset = 'abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let newPassword = ""
  for (let i = 0; i < 8; i++) {
    newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
  }
  return newPassword
}
const certPsw = generatePassword()

export function CertAction(props: { data: CertActionVariables }) {
  const { data } = props
  const { $t } = useIntl()
  const { Title, Text, Link } = Typography
  const auth = useSelector((state: RootState) => state.auth);
  const [selectedSsid, setSelectedSsid] = useState<string>('')
  const networkList = data.NETWORKS.split(',')
  const downloadCertUrl = new URL(`/deviceEnrollments/workflows/${auth.workflowId}/certificates?auth=${auth.token}&password=${certPsw}`, location.origin).href

  useEffect(() => {
    if (networkList.length === 1) {
      setSelectedSsid(networkList[0])
    }
  }, [])

  if (networkList.length === 0 || networkList[0] === '') {
    return (<ErrorPage message={$t({ defaultMessage: 'No networks available.' })} />)
  }
  const downloadCertificate = (url: string) => {
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.target = '_blank';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }
  return (
    <Space direction='vertical' size='large' align='center'>
      <Title level={2} >
        {$t({ defaultMessage: 'Connect to the network' })}
      </Title>

      {(networkList.length > 1 && selectedSsid === '') ?
        <><Text strong>
            {$t({ defaultMessage: 'Install certificate in order to connect to the following networks:' })}
          </Text>
          <List
            dataSource={selectedSsid ? [selectedSsid] : networkList}
            renderItem={(ssid, index) => (
              (ssid && ssid.trim().length > 0) &&

              <List.Item>
                <Row style={{ width: '100%' }}>

                  {(networkList.length > 1) ?
                    <Link data-testid={`lnw-${index}`}
                      onClick={() => setSelectedSsid(selectedSsid ? '' : ssid)}
                      strong={true}>
                      <Text strong={true}>{ssid}</Text>
                    </Link>
                    : <Text strong={true}>{selectedSsid}</Text>}

                </Row>
              </List.Item>

            )} />
          <Text>
            {$t({ defaultMessage: 'Scan or click the QR code to download the certificate:' })}
          </Text>
        </> :
        <Space direction='vertical'>
          <Space>
            <Text strong >
              {$t({ defaultMessage: 'Wi-Fi Network name: ' })}
            </Text>
            <Text>{selectedSsid}</Text>
          </Space>
          <Text>
            {$t({ defaultMessage: 'Scan or click this QR code to download the certificate that is required in order to connect to the network:' })}
          </Text>
        </Space>
      }
      <QRCodeSVG data-testid='qr-code'
        value={downloadCertUrl}
        size={180}
        bgColor={'#ffffff'}
        fgColor={'#000000'}
        level={'L'}
        includeMargin={false}
        onClick={() => {
          if (networkList.length > 1) {
            setSelectedSsid('');
          }
          downloadCertificate(downloadCertUrl)
        }} />

      <Space>
        <Text strong>
          {$t({ defaultMessage: 'Certificate file password:' })}
        </Text>
        <Text>{certPsw}</Text>
      </Space>

    </Space>
  )
}
